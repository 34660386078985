import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function RedirectLink() {
    const params = useParams();
    const redirectToChannelPage = () => {
        window.location.href = `https://hermes.piramide.tv/r/${params.id}`;
    }

    useEffect(() => {
        document.title = `${params.id} - Redirect - Piramide TV`;
        redirectToChannelPage();
    });

    return(<div></div>)
}

export default RedirectLink;
