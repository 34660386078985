import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import './VideoPage.css';
import RecommendedChannels from '../RecommendedChannels/RecommendedChannels.tsx';
import VideoPlayer from '../VideoPlayer/VideoPlayer.tsx';
import VideosGrid from '../VideosGrid/VideosGrid.tsx';
import API_BASE_URL from '../constants.tsx';
import { Video } from  '../interfaces.tsx';
import TopVideos from '../TopVideos/TopVideos.tsx';
import OlimpoBannerMobile from '../OlimpoHostingBanner/OlimpoBannerMobile/OlimpoBannerMobile.tsx';

interface VideoData {
    id: string;
    title: string;
    description: string;
    date: string;
    state: number;
    channel: {
        id: string,
        name: string,
        media: { profile: string };
    };
    node: { id: string, url: string };
    media: { thumbnail: string, video: string };
    next_video: {
        enabled: boolean;
        id: string;
        title: string;
        state: number;
        duration: number;
        channel: {
          id: string;
          name: string;
          media: { profile: string };
        };
        media: { thumbnail: string };
    };
}

function VideoPage() {
    let navigate = useNavigate();
    const params = useParams();
    const [video, setVideo] = useState<VideoData | null>(null);
    const [videos, setVideos] = useState<Video[]>([]);
    let topThreeVideos: Video[] = [];
    let recommendedVideos: Video[] = [];

    const handleChannelClick = (channelId: string) => {
        navigate(`/channel/${channelId}`);
    }

    useEffect(() => {
        fetch(`${API_BASE_URL}/video/data/${params.id}`)
            .then(response => response.json())
            .then(json => {
                document.title = `${json.video.title} - Piramide TV`;
                setVideo(json.video);
            })
            .catch(error => {
                console.error(error)
            });

        fetch(`${API_BASE_URL}/video/recommended`)
            .then(response => response.json())
            .then(json => setVideos(json.videos))
            .catch(error => console.error(error));
    }, [params.id]);

    if (videos) {
        const filteredVideos = videos.filter((el) => el.id !== params.id);
        topThreeVideos = filteredVideos.slice(0, 3);
        recommendedVideos = filteredVideos.slice(3);
    }

    return (
        <div className='video-page-container'>
            <div className='top-videos-main-container'>
                <div className='main-video'>
                    {video != null ?
                        <>
                            <VideoPlayer
                                video={{
                                    id: video.id,
                                    sourceUrl: video.media.video,
                                    channelId: video.channel.id,
                                    nextVideoId: video.next_video?.id,
                                    nextVideoThumbnail: video.next_video?.media?.thumbnail,
                                    isTest: !!params.test,
                                }}
                            />
                            <div className='main-video__details'>
                                <div className='main-video__details__channel-image' onClick={() => handleChannelClick(video.channel.id)}>
                                    <img className='video-image' alt='Channel' src={video.channel.media.profile}/>
                                </div>
                                <div className='main-video__details__metadata'>
                                    <div className='main-video__details__metadata__title'>{video.title}</div>
                                    <div className='main-video__details__metadata__channel-name'>{video.channel.name}</div>
                                </div>
                            </div>
                        </> :
                        null
                    }
                </div>
                <TopVideos topVideos={topThreeVideos.slice(1,3)} />
            </div>
            <RecommendedChannels/>
            <OlimpoBannerMobile/>
            <VideosGrid videos={recommendedVideos}/>
        </div>
    );
}

export default VideoPage;
