import React, { Suspense, lazy } from 'react';
import { Video } from  '../interfaces.tsx';
import './VideosGrid.css';

const VideoInGrid = lazy(() => import('./VideoInGrid/VideoInGrid.tsx'));

function VideosGrid({ videos }) {
    return (
        <div className='videos-container'>
            {videos?.map((video: Video) =>
                <div className='videos-container__video' key={video.id}>
                    <Suspense fallback={<div className='loader'></div>}>
                        <VideoInGrid video={video}/>
                    </Suspense>
                </div>
            )}
        </div>
    )
}

export default VideosGrid;
