import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ChannelPage.css';
import VideosGrid from '../VideosGrid/VideosGrid.tsx';
import API_BASE_URL from '../constants.tsx';

interface Channel {
    channel: { id: string, name: string },
    media: { banner: string, profile: string }
}

interface Video {
    id: string,
    channel: { id: string, name: string, media: { profile: string } },
    duration: number,
    media: { thumbnail: string },
    title: string
}

function ChannelPage() {
    const params = useParams();
    const [channelInfo, setChannelInfo] = useState<Channel | null>(null);
    const [videosSection, setVideosSection] = useState<'recientes' | 'populares'>('recientes');
    const [popularesVideos, setPopularesVideos] = useState<Video[]>([]);
    const [recientesVideos, setRecientesVideos] = useState<Video[]>([]);

    const handleActionButtonClick = (buttonId: string) => {
        if (buttonId === 'recientes') {
            setVideosSection('recientes');
        } else if (buttonId === 'populares') {
            setVideosSection('populares');
        }
    }

    useEffect(() => {
        fetch(`${API_BASE_URL}/channel/data/${params.id}`)
            .then(response => response.json())
            .then(json => {
                document.title = `${json.channel.name} - Piramide TV`;
                setChannelInfo(json);
            })
            .catch(error => {
                console.error(error)
                // do something when there is an error
            });
        
        fetch(`${API_BASE_URL}/channel/list/${params.id}/date/100`)
            .then(response => response.json())
            .then(json => setRecientesVideos(json.videos))
            .catch(error => {
                console.error(error)
                // do something when there is an error
            });

        fetch(`${API_BASE_URL}/channel/list/${params.id}/popularity/100`)
            .then(response => response.json())
            .then(json => setPopularesVideos(json.videos))
            .catch(error => {
                console.error(error)
                // do something when there is an error
            });
    }, [params.id]);

    const videosToDisplay = () => {
        if (videosSection === 'recientes') {
            return recientesVideos;
        }
        if (videosSection === 'populares') {
            return popularesVideos;
        }
    }

    return (
        <div className='channel-page'>
            <div className='channel-page__header'>
                <div className='channel-page__header__banner' style={{backgroundImage: `url(${channelInfo?.media.banner})`}}></div>
                <div className='channel-page__header__info'>
                    <div className='channel-page__header__info__channel-image'>
                        <img alt={channelInfo?.channel.name} src={channelInfo?.media.profile} />
                    </div>
                    <div className='channel-page__header__info__channel-details'>
                        <div className='channel-name'>{channelInfo?.channel.name}</div>
                        <div className='channel-id'>@{channelInfo?.channel.id}</div>
                        <div className='channel-videos-number'>{popularesVideos.length} videos</div>
                    </div>
                </div>
            </div>
            <div className='channel-page__action-menu'>
                <button id='recientes' className={videosSection === 'recientes' ? 'active' : undefined}
                    onClick={() => handleActionButtonClick('recientes')}>Recientes
                </button>
                <button id='populares' className={videosSection === 'populares' ? 'active' : undefined}
                    onClick={() => handleActionButtonClick('populares')}>Populares
                </button>
            </div>
            <VideosGrid videos={videosToDisplay()}/>
        </div>
    )
}

export default ChannelPage;
