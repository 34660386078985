import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import JWPlayer from '@jwplayer/jwplayer-react';

import API_BASE_URL from '../constants.tsx';
import './VideoPlayer.css';
import { NextVideo } from '../interfaces.tsx';

type Video = {
  id: string;
  sourceUrl: string;
  channelId: string;
  nextVideoId: string;
  nextVideoThumbnail: string;
  isTest: boolean;
};

function VideoPlayer({ video }: { video: Video }) {
  let navigate = useNavigate();

  const playerRef = useRef(null);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [nextVideo, setNextVideo] = useState<NextVideo | null>(null);
  const [showNextVideoLayout, setShowNextVideoLayout] = useState<boolean>(false);

  const onReady = () => {
    setTimeout(() => {
      setVideoPlayed(true);
      if (video.nextVideoId || nextVideo?.id !== video.id) {
        setShowNextVideoLayout(true);
      }
    }, 20000)
  };

  const onCloseNextVideoLayout = () => {
    setShowNextVideoLayout(false);
  };

  const handleVideoEnded = () => {
    if (video.nextVideoId) {
      navigate(`/video/${video.nextVideoId}`);
    } else if (nextVideo?.id !== video.id) {
      navigate(`/video/${(nextVideo as NextVideo).id}`);
    }
  };

  useEffect(() => {
    if (!video.nextVideoId) {
      fetch(`${API_BASE_URL}/channel/list/${video.channelId}/views/1`)
        .then(response => response.json())
        .then(json => setNextVideo(json.videos[0]))
        .catch(error => console.error(error));
    }
  
    if (playerRef.current && typeof window !== 'undefined') {
      (window as any).jwplayer(playerRef.current).setup({
        playlist: video.sourceUrl,
        width: '100%',
        aspectratio: '16:9',
        skin: {
          name: 'myskin'
        }
      });
    }

    if (videoPlayed) {
      fetch(`${API_BASE_URL}/video/play/${video.id}`, { method: 'POST' })
        .then(response => response.json())
        .catch(error => console.error(error));
    }
  }, [video, videoPlayed]);

  return (
    <div className='video-player'>
      <JWPlayer
        library={`https://cdn.jwplayer.com/libraries/${video?.isTest ? 'QY3Daple' : 'fOqQ4Rf8'}.js`}
        playlist={video.sourceUrl}
        key={video.sourceUrl}
        onReady={() => onReady()}
        onComplete={() => handleVideoEnded()}
      />
      { showNextVideoLayout === true ?
        <div className='thumbnail-details'>
          <div className='thumbnail-controls'>
            <div className='thumbnail-text'>Siguiente</div>
            <div className='thumbnail-close-button' onClick={onCloseNextVideoLayout}><strong>X</strong></div>
          </div>
          <img
            className='thumbnail-image'
            alt='Next video'
            src={`${video.nextVideoThumbnail ? video.nextVideoThumbnail : (nextVideo as NextVideo).media.thumbnail}`}
          ></img>
        </div>
       : null
      }
    </div>
  );
};

export default VideoPlayer;
