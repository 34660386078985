import React, { useEffect } from 'react';
import './Privacity.css';

function Privacity() {
    useEffect(() => {
        document.getElementById('privacity')?.classList.add('active');
    });

    return(
        <div className='privacity-content'>
            <h2>PRIVACY POLICY</h2>
            <h3>1. Data of the treatment controller</h3>
            Company Name: SCIA DEVELOPERS, SL (hereinafter, the “Company” or the “Responsible”).
            <br/>
            CIF: B05418447
            <br/>
            Address: CARRER DIPUTACIO, Nº338, ENTRESUELO 3 - 08009 - BARCELONA - BARCELONA
            <br/>
            Telephone:
            <br/>
            Email for communications regarding Data Protection: sergi@mcr-agency.com
            <h4>1.1. Applicable regulations</h4>
            Our Privacy Policy has been designed in accordance with theEU General Data Protection Regulation
            2016/679 of the European Parliament and of the Council, of April 27, 2016, regarding the protection of natural
            persons with regard to processing of personal data and the free circulation of these data and which repeals
            Directive 95/46/EC (General Data Protection Regulation), and Organic Law 3/2018 of December 5,
            on Data Protection Personal Nature and Guarantee of Digital Rights.
            <br/>
            By providing us with your data, you declare that you have read and know this Privacy Policy, giving your unequivocal
            and express consent to the processing of your personal data in accordance with the purposes and terms expressed here.
            <br/>
            The Company may modify this Privacy Policy to adapt it to new legislation, jurisprudence or interpretation of
            the Spanish Data Protection Agency. These privacy conditions may be complemented by the Legal Notice, Cookies
            Policy and the General Conditions that, where appropriate, are collected for certain products or services,
            if said access involves some specialty in terms of protection of personal data.
            <h4>1.2. Data Protection Officer</h4>
            There is no designated Data Protection Officer.
            <h3>2. Purpose of the processing of personal data</h3>
            The processing we carry out on your personal data responds to the following purposes:
            <li>
                Provide you with information related to the products and services offered by our company and detailed on this website.
            </li>
            <li>
                Carry out the contracting of our services by accepting the corresponding quote / order and/or signing a commercial contract.
            </li>
            <li>
                Send you by email and/or postal mail news and updates about our entity, as well as updates to our catalog of products and
                services.
            </li>
            <h4>2.1. Retention period of your data</h4>
            We will keep your personal data from the time you give us your consent until you revoke it or request the limitation of processing.
            In such cases, we will keep your data locked for the legally required periods.
            <h3>3. Legitimation and data collected</h3>
            The legitimacy for the processing of your data is the express consent granted through a positive
            and affirmative act (fill out the corresponding form and check the box accepting this policy) at the time
            you provide us with your personal data.
            <h4>3.1. Consent to process your data</h4>
            By filling out the forms, checking the “I accept the Privacy Policy” box and clicking to send the data,
            or by sending emails to the Company through the accounts enabled for this purpose, the User states that they have
            read and expressly accepted this document. privacy policy, and grants your unequivocal and express consent to the
            processing of your personal data in accordance with the indicated purposes.
            <h4>3.2. Data categories</h4>
            The data collected refers to the category of identifying data, such as: Name and Surname, Telephone,
            Postal Address, Company, Email, as well as the IP address from where you access the data collection form.
            <h3>4. Security measures</h3>
            As part of our commitment to guarantee the security and confidentiality of your personal data, we inform you that
            the necessary technical and organizational measures have been adopted to guarantee the security of your personal data
            and prevent its alteration, loss, processing or unauthorized access, taking into account the state of technology,
            the nature of the data stored and the risks to which they are exposed, according to Art. 32 of the RGPD EU 679/2016.
            <h3>5. Transfer of data</h3>
            No data transfers or international transfers of your data are foreseen, except for those authorized by tax,
            commercial and telecommunications legislation, as well as in those cases in which a judicial authority requires it.
            <h3>6. User rights</h3>
            Any interested party has the right to obtain confirmation as to whether we are processing personal data that
            concerns them or not. Interested persons have the right to access their personal data, as well as to request the
            rectification of inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is
            no longer necessary for the purposes for which it was collected. In certain circumstances, interested parties may
            request the limitation of the processing of their data, in which case we will only keep them for the exercise or defense
            of claims. For reasons related to their particular situation, interested parties may object to the processing of their data.
            The person responsible for the file will stop processing the data, except for compelling legitimate reasons, or the exercise
            or defense of possible claims.
            <br/>
            In accordance with current legislation, you have the following rights: right to request access to your personal data,
            right to request its rectification or deletion, right to request the limitation of its processing, right to oppose processing,
            right to portability of data. data and likewise, to revoke the consent granted . Likewise, you have the right to file a
            claim with the Spanish Data Protection Agency.
            <h4>6.1. How to exercise my rights?</h4>
            To exercise your rights, you must contact the person responsible, requesting the corresponding form for the exercise
            of the chosen right. Optionally, you can go to the competent Control Authority to obtain additional information about
            your rights. The contact information for the exercise of your rights is the telephone number and email: sergi@mcr-agency.com.
            Remember to include a copy of a document that allows us to identify you.
            <h3>7. Consent to send electronic communications</h3>
            Likewise, and in accordance with the provisions of Law 34/2002, of July 11, on Information Society Services and
            Electronic Commerce, completing the data collection form and checking the corresponding box “I accept the shipment of
            electronic communications”, you are giving express consent to send information about the Company to your email address,
            telephone, fax or other electronic means.
        </div>
    )
}

export default Privacity;
