import React, { useEffect } from 'react';
import './InformacionCoorporativa.css';

function InformacionCoorporativa() {
    useEffect(() => {
        document.getElementById('informacion-coorporativa')?.classList.add('active');
    });

    return(
        <div className='informacion-coorporativa-content'>
            <h2>INFORMACIÓN COORPORATIVA</h2>
            Identificación del prestador de servicios de la sociedad de la información
            <br/>
            <br/>
            Con la finalidad de dar cumplimiento al artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de
            la Información y Comercio Electrónico, informamos al Usuario de nuestros datos:
            <br/>
            <br/>
            Sitio Web: www.piramide.tv
            <br/>
            <br/>
            Entidad: SCIA DEVELOPERS, S.L y ARTEMIY VILKOV
            <br/>
            <br/>
            Nombre Comercial: PIRAMIDE.TV
            <br/>
            <br/>
            Domicilio: CARRER DIPUTACIO, Nº338, ENTRESUELO 3 - 08009 - BARCELONA - BARCELONA
            <br/>
            <br/>
            NIF: B05418447
            <br/>
            <br/>
            Teléfono:
            <br/>
            <br/>
            E-mail: sergi@mcr-agency.com
        </div>
    )
}

export default InformacionCoorporativa;
