import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TopVideos.css';
import OlimpoBannerDesktop from '../OlimpoHostingBanner/OlimpoBannerDesktop/OlimpoBannerDesktop.tsx';
import { Video } from '../interfaces.tsx';

function TopVideos({topVideos}) {
    let navigate = useNavigate();

    const handleVideoClick = (videoId: string) => {
        navigate(`/video/${videoId}`);
    }

    const handleChannelClick = (channelId: string) => {
        navigate(`/channel/${channelId}`);
    }

    return(
        <div className='top-videos-secondary-container'>
            {topVideos.map((video: Video) => 
                <div className='secondary-content' key={video.id}>
                    <div className='secondary-content__image' onClick={() => handleVideoClick(video.id)}>
                        <img alt='Top Trending Video' src={video?.media.thumbnail}/>
                    </div>
                    <div className='secondary-content__details'>
                        <div className='secondary-content__details__channel-image' onClick={() => handleChannelClick(video.channel.id)}>
                            <img alt='Channel' src={video.channel.media.profile}/>
                        </div>
                        <div className='secondary-content__details__metadata' onClick={() => handleVideoClick(video.id)}>
                            <div className='secondary-content__details__metadata__title'>{video?.title}</div>
                            <div className='secondary-content__details__metadata__channel-name'>{video?.channel.name}</div>
                        </div>
                    </div>
                </div>
            )}
            <OlimpoBannerDesktop/>
        </div>
    );
}

export default TopVideos;
