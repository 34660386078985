import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import RecommendedChannels from '../RecommendedChannels/RecommendedChannels.tsx';
import VideosGrid from '../VideosGrid/VideosGrid.tsx';
import API_BASE_URL from '../constants.tsx';
import { Video } from  '../interfaces.tsx';
import TopVideos from '../TopVideos/TopVideos.tsx';
import OlimpoBannerMobile from '../OlimpoHostingBanner/OlimpoBannerMobile/OlimpoBannerMobile.tsx';

function HomePage() {
    const [videos, setVideos] = useState<Video[]>([]);
    let navigate = useNavigate();
    let topThreeVideos: Video[] = [];
    let recommendedVideos: Video[] = [];

    const handleVideoClick = (videoId: string) => {
        navigate(`/video/${videoId}`);
    };

    const handleChannelClick = (channelId: string) => {
        navigate(`/channel/${channelId}`);
    };

    useEffect(() => {
        document.title = 'Inicio - Piramide TV';
        window.scrollTo(0, 0);
        fetch(`${API_BASE_URL}/video/recommended`)
            .then(response => response.json())
            .then(json => setVideos(json.videos))
            .catch(error => console.error(error));
    }, []);

    if (videos?.length) {
        topThreeVideos = videos.slice(0, 3);
        recommendedVideos = videos.slice(3);
    }

    return(
        <div>
            <main className='main-container'>
                <div className='main-content'>
                    <div className='top-videos-main-container'>
                        <div className='main-video'>
                            <div
                                className='main-video__image-desktop'
                                style={{backgroundImage: `url(${topThreeVideos[0]?.media.thumbnail})`}}
                                onClick={() => handleVideoClick(topThreeVideos[0]?.id)}
                            ></div>
                            <div className='main-video__image-mobile' onClick={() => handleVideoClick(topThreeVideos[0]?.id)}>
                                <img alt='Top Trending Video' src={topThreeVideos[0]?.media.thumbnail}/>
                            </div>
                            <div className='main-video__details'>
                                <div className='main-video__details__channel-image'>
                                    <img
                                        className='video-image'
                                        alt='Channel'
                                        src='https://static.piramide.tv/channel/arta_game/profile.jpg'
                                        onClick={() => handleChannelClick(topThreeVideos[0].channel.id)}
                                    />
                                </div>
                                <div className='main-video__details__metadata' onClick={() => handleVideoClick(topThreeVideos[0]?.id)}>
                                    <div className='main-video__details__metadata__title'>{topThreeVideos[0]?.title}</div>
                                    <div className='main-video__details__metadata__channel-name'>{topThreeVideos[0]?.channel.name}</div>
                                </div>
                            </div>
                        </div>
                        <TopVideos topVideos={topThreeVideos.slice(1,3)} />
                    </div>
                    <RecommendedChannels/>
                    <OlimpoBannerMobile />
                    <VideosGrid videos={recommendedVideos}/>
                </div>
            </main>
        </div>
    );
}

export default HomePage;
