import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import './App.css';
import HomePage from './components/HomePage/HomePage.tsx';
import NotFound from './components/NotFound/NotFound.tsx';
import ChannelPage from './components/ChannelPage/ChannelPage.tsx';
import Header from './components/Header/Header.tsx';
import Footer from './components/Footer/Footer.tsx';
import VideoPage from './components/VideoPage/VideoPage.tsx';
import Cookies from './components/FooterContent/Cookies/Cookies.tsx';
import Legal from './components/FooterContent/Legal/Legal.tsx';
import Privacity from './components/FooterContent/Privacity/Privacity.tsx';
import InformacionCoorporativa from './components/FooterContent/InformacionCoorporativa/InformacionCoorporativa.tsx';
import GestionPublicitaria from './components/FooterContent/GestionPublicitaria/GestionPublicitaria.tsx';
import RedirectLink from './components/RedirectLink/RedirectLink.tsx'

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" Component={HomePage} />
        <Route path="*" Component={NotFound} />
        <Route path="/channel/:id" Component={ChannelPage} />
        <Route path="/video/:id" Component={VideoPage} />
        <Route path="/:test/video/:id" Component={VideoPage} />
        <Route path="/cookies" Component={Cookies} />
        <Route path="/legal" Component={Legal} />
        <Route path="/privacity" Component={Privacity} />
        <Route path="/informacion-coorporativa" Component={InformacionCoorporativa} />
        <Route path="/gestion-publicitaria" Component={GestionPublicitaria} />
        <Route path='/r/:id' Component={RedirectLink} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
