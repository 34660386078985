import React from 'react';
import './Footer.css';

function Footer() {
    return(
        <footer className='footer-container'>
            <div className='footer-content'>
                <ul className='foooter-content__links'>
                    <li className='foooter-content__link'>
                        <a href='/cookies' id='cookies'>Política de Cookies</a>
                    </li>
                    <li className='foooter-content__link'>
                        <a href='/legal' id='legal'>Aviso legal</a>
                    </li>
                    <li className='foooter-content__link'>
                        <a href='/privacity' id='privacity'>Política de privacidad</a>
                    </li>
                    <li className='foooter-content__link'>
                        <a href='/informacion-coorporativa' id='informacion-coorporativa'>Información coorporativa</a>
                    </li>
                    <li className='foooter-content__link'>
                        <a href='/gestion-publicitaria' id='gestion-publicitaria'>Gestión publicitaria</a>
                    </li>
                </ul>
                <div className='copyright'>
                    <a href='https://piramide.tv/'>Piramide.tv</a>
                    © 2022 - 2024
                </div>
            </div>
        </footer>
    );
}

export default Footer;
