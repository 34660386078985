import React, { useEffect } from 'react';
import './GestionPublicitaria.css';

function GestionPublicitaria() {
    useEffect(() => {
        document.getElementById('gestion-publicitaria')?.classList.add('active');
    });

    return(
        <div className='gestion-publicitaria-content'>
            <h2>GESTIÓN PUBLICITARIA</h2>
            Le informamos que los datos personales que Usted nos proporciona son incorporados a un tratamiento de datos
            personales denominado CONTACTOS WEB SITE cuyo responsable es SCIA DEVELOPERS, S.L. con NIF B05418447 ambos con
            domicilio en CARRER DIPUTACIO, Nº338, ENTRESUELO 3, 08009 de BARCELONA, BARCELONA (ESPAÑA). Puede contactar con
            el Responsable, bien por teléfono en el número o bien mediante correo electrónico en el buzón hello@mcr-agency.com.
            <h3>Delegado de Protección de Datos</h3>
            No hay Delegado de Protección de Datos designado.
            <h3>Finalidad del tratamiento</h3>
            A través de los formularios de toma de datos incluidos en el sitio web del responsable, las personas que lo
            deseen pueden enviar sus mensajes, solicitudes de información, peticiones, etc acerca de los productos
            y/o servicios ofrecidos por el responsable del tratamiento.  
            <h3>Plazo de Conservación</h3>
            El plazo de conservación es: Los datos personales se conservarán hasta la retirada del consentimiento por parte del interesado.
            <h3>Decisiones automatizadas y elaboración de perfiles</h3>
            No existen decisiones automatizadas ni elaboración de perfiles.
            <h3>Base Jurídica del Tratamiento</h3>
            La legitimación para la realización de este tratamiento radica en el consentimiento del interesado.
            <h3>Destinatarios de Cesiones</h3>
            No se prevén realizar cesiones a terceros, salvo aquellas que están autorizadas por ley.
            <h3>Transferencias Internacionales</h3>
            No se realizan transferencias internacionales.
            <h3>Derechos de los interesados</h3>
            Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que les conciernan,
            o no. Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación
            de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios
            para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la limitación del
            tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
            Por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos.
            El Responsable del fichero dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa
            de posibles reclamaciones. También se informa al interesado que puede presentar una reclamación ante la Autoridad
            de Control en materia de Protección de Datos.
        </div>
    )
}

export default GestionPublicitaria;
