import React from 'react';
import './NotFound.css';

function NotFound() {
    return(
        <div className='page-not-found'>
            <h1>ERROR 404</h1>
            <h2>PAGINA NO ENCONTRADA</h2>
        </div>
    );
}

export default NotFound;
