import React from 'react';
import './OlimpoBannerDesktop.css';
import { olimpoBanner } from '../OlimpoHostingBanner.tsx';

function OlimpoBannerDesktop() {
    return(
        <a className='olimpo-hosting-brand' href='https://olimpohost.com/'>
            <img alt='Olimpo Hosting' src={olimpoBanner}/>
        </a>
    );
}

export default OlimpoBannerDesktop;
