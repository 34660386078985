import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './RecommendedChannels.css';
import API_BASE_URL from '../constants.tsx';

interface Channel {
    id: string,
    name: string,
    media: {
        profile: string
    }
}

function RecommendedChannels() {
    let navigate = useNavigate();
    const visibleChannels = window.innerWidth > 1031 ? 5 : 4;
    const [channels, setChannels] = useState<Channel[]>([]);
    const [startIdx, setStartIdx] = useState<number>(0);
    const [endIdx, setEndIdx] = useState<number>(visibleChannels);

    const handleChannelClick = (channelId: string) => {
        navigate(`/channel/${channelId}`);
    }

    const onPrevChannels = () => {
        setStartIdx(startIdx - 1);
        setEndIdx(endIdx - 1);
    }

    const onNextChannels = () => {
        setStartIdx(startIdx + 1);
        setEndIdx(endIdx + 1);
    }

    useEffect(() => {        
        fetch(`${API_BASE_URL}/channel/recommended`)
            .then(response => response.json())
            .then(json => setChannels(json.channels))
            .catch(error => console.error(error));
    }, []);

    return(
        <div className='channels-content'>
            <div
                className={startIdx === 0 ? 'channels-control-button inactive' : 'channels-control-button'}
                onClick={onPrevChannels}>
                    <svg fill="#000000" height="15" width="11" viewBox="0 0 300 300">
                        <path id="XMLID_227_"
                            d="M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3
	                        L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001
	                        C236.878,322.03,238.221,312.628,233.25,306.001z"/>
                    </svg>
            </div>
            <div className='channels-container'>
                {channels.slice(startIdx, endIdx).map((channel) =>
                    <div className='recommended__channel' key={channel.id} onClick={() => handleChannelClick(channel.id)}>
                        <img alt={channel.name} src={channel.media.profile} />
                        <div className='recommended__channel__name'>{channel.name}</div>
                    </div>
                )}
            </div>
            <div
                className={endIdx === channels.length ? 'channels-control-button inactive' : 'channels-control-button'}
                onClick={onNextChannels}>
                    <svg fill="#000000" height="15" width="11" viewBox="0 0 330 330">
                    <path id="XMLID_226_"
                        d="M233.252,155.997L120.752,6.001c-4.972-6.628-14.372-7.97-21-3c-6.628,4.971-7.971,14.373-3,21
                    	l105.75,140.997L96.752,306.001c-4.971,6.627-3.627,16.03,3,21c2.698,2.024,5.856,3.001,8.988,3.001
                    	c4.561,0,9.065-2.072,12.012-6.001l112.5-150.004C237.252,168.664,237.252,161.33,233.252,155.997z"/>
                    </svg>
            </div>
        </div>
    );
}

export default RecommendedChannels;
